import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './SideBar.module.css'
import _ from 'lodash'
import { paths } from '../../common/consts'
import faces from '../../common/assets/faces'
import linkedIn from '../../common/assets/linkedin.png'
import email from '../../common/assets/icons8-email-open-48.png'

const pathFaces = {
    [paths.home.path]: faces.jl_face,
    [paths.about.path]: faces.smile_face,
    [paths.resume.path]: faces.read_face,
    // [paths.projects.path]: faces.qq_face,
}

const SideBar = () => {
    const [face, setFace] = useState(paths.home.path)
    const links = _.map(paths, (path) => {
        return (
            <NavLink key={path.name} to={path.path} onClick={()=>{ setFace(path.path) }}
                className={styles.link} activeClassName={styles.activeLink}>
                {path.name}
            </NavLink>
        )
    })

    return <div className={styles.container}>
        <div className={styles.imgWrapper}>
            <img className={styles.cartoonFace} src={pathFaces[face]} alt='cartoon face'/>
        </div>
        {links}
        <div className={styles.contactContainer}>
            <div className={styles.contactTitle}>Contact Me</div>
            <div className={styles.icons}>
                <a href="mailto:jeffrey.clam95@gmail.com">
                    <img className={styles.emailIcon} src={email} alt='email address' />
                </a>
                <a href="https://www.linkedin.com/in/jeffrey-c-lam" target="_blank" rel='noopener noreferrer'>
                    <img className={styles.inIcon} src={linkedIn} alt='linkedin profile' />
                </a>
            </div>
        </div>
    </div>
}

export default SideBar