import React from 'react'
import styles from './ContentContainer.module.css'

const ContentContainer = ({children, title}) => {
    return (
        <div className={styles.content}>
            <div className={styles.contentTitle}>{title}</div>
            <div className={styles.body}>
                {children}
            </div>
        </div>
    )
}

export default ContentContainer