import React from 'react'
import styles from './AboutView.module.css'
import ContentContainer from '../../components/ContentContainer'
import selfPortrait from '../../common/assets/self_portrait.png'

const AboutView = () => {
    return <ContentContainer title='About Me'>
        <p>I am a Full Stack Software Engineer at Fora Travel, Inc.</p>
        <p>I am a graduate from Cal Poly SLO with a Bachelor's in Computer Science.</p>
        <p>I am a first-generation Chinese American citizen born and raised in Sacramento.</p>
        <p>I am a late-20s-something year old living in New York City.</p>
        <p>I am growing as a leader, developer, and person.</p>
        <p>I am looking to make a positive impact on my community, my society, and the world.</p>
        <img className={styles.selfPortrait} src={selfPortrait} alt='vectorized self portrait'/>
    </ContentContainer>
}

export default AboutView