import React from 'react'
import styles from './WorkTile.module.css'
import _ from 'lodash'

const WorkTile = ({job}) => {
    const {jobTitle, company, timeFrame, description} = job
    return (
        <div className={styles.tile}>
            <div className={styles.title}>
                <span>{jobTitle}</span>
                <span>{timeFrame}</span>
            </div>
            <span>{company}</span>
            <ul className={styles.list}>
                {_.map(description, (bullet, idx) => {
                    return <li className={styles.item} key={idx} >{bullet}</li>
                })}
            </ul>
        </div>
    )
}

export default WorkTile