import React from 'react'
import { 
  BrowserRouter as Router, 
  Redirect, 
  Route, 
  Switch 
} from 'react-router-dom'
import './App.css'
import HomeView from './views/HomeView'
import AboutView from './views/AboutView'
import ResumeView from './views/ResumeView'
import SideBar from './components/SideBar'
import { paths } from './common/consts'
// import ProjectsView from './views/ProjectsView/ProjectsView'

function App() {
  return (
    <div className='App'>
      <Router>
        <SideBar />
        <Switch>
          <Route exact path='/'><Redirect to={paths.home.path} /></Route>
          <Route path={paths.home.path}><HomeView /></Route>
          <Route path={paths.about.path}><AboutView /></Route>
          <Route path={paths.resume.path}><ResumeView /></Route>
          {/* <Route path={paths.projects.path}><ProjectsView /></Route> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
